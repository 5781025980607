import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { features_carousel_data } from "../../data/features_carousel_data";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

const Features_carousel = () => {
  const [verifyLocation, setVerifyLocation] = useState()

  useEffect(() => {
    if (window.location.pathname === '/') {
      setVerifyLocation(true)
    } else {
      setVerifyLocation(false)
    }
  })

  return (
    <>
      <Swiper
        modules={[Pagination]}
        spaceBetween={30}
        slidesPerView="auto"
        loop={false}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        pagination={{ clickable: true }}
        className="card-slider-4-columns !py-5 !overflow-visible"
      >
        {features_carousel_data.map((item) => {
          const { id, img, title, desc } = item;
          return (
            <SwiperSlide className="text-white cursor-grab active:cursor-grabbing" key={id}>
              <div className="rounded-2.5xl bg-jacarta-1300 p-10"
                style={{ minHeight: '400px' }}
              >
                <div className="mb-4 md:mb-0 ">
                  <figure className="mb-8">
                    <Image src={img} alt="" height={48} width={48} />
                  </figure>
                  <h3 className="mb-4 font-display text-lg text-white">
                    {title}
                  </h3>
                  <p className="text-jacarta-300 pb-4">{desc}</p>
                  {verifyLocation && (
                    <Link href="/acerca">
                      <a rel="noopener noreferrer" className="text-accent hover:dark:text-accent-dark cursor-pointer">VER MÁS</a>
                    </Link>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default Features_carousel;
