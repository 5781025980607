export const features_carousel_data = [
  {
    title: "Robots Sociales",
    desc: "Diseñamos y fabricamos robots capaces de interactuar con las personas, ofrecer una experiencia diferente para impactar en ellas y poder educar, guiar, conectar y promover.",
    img: "/images/features/robots.svg",
    id: 1,
  },
  {
    title: "Consultoría de Ingeniería",
    desc: "Ofrecemos nuestra experiencia en mecánica, informática, electrónica y mecatrónica para servicios de consultoría que busquen Automatización y Optimización de procesos, Reingeniería de diseño, etc.",
    img: "/images/features/ingenieria.svg",
    id: 2,
  },
  {
    title: "Diseño de Productos",
    desc: "Elaboramos productos a pedido del cliente apoyados en técnicas de diseño industrial centrados en el usuario y la experiencia de uso.",
    img: "/images/features/productos.svg",
    id: 3,
  },
  {
    title: "Desarrollo Tecnológico",
    desc: "Ofrecemos soluciones tecnológicas adecuadas a las necesidades del cliente. Desarrollamos propuestas que incluyan Robótica, IoT, Inteligencia Artificial, Desarrollo de hardware y software.",
    img: "/images/features/tech.svg",
    id: 4,
  },
  {
    title: "Impresión 3D",
    desc: "Ofrecemos el servicio de fabricación digital personalizado para cada cliente, desde el diseño y modelado 3D hasta el servicio de fabricación por técnicas de FDM para impresión 3D.",
    img: "/images/features/3d.svg",
    id: 5,
  },

];
