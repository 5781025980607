import Link from 'next/link';
import React from 'react';
import Characters_carousel from './carousel/characters_carousel';

const Calltoaction = () => {
    return (
        <Link href="/contacto">
            <div className="dark:bg-accent bg-accent w-screen flex items-center cursor-pointer group hover:dark:bg-white ease-out duration-300 py-2"> 
                <h2 className="flex mx-auto max-w-md text-1xl font-medium text-black text-center">
                ¿Quieres desarrollar un proyecto? Conversemos
                <img
                    src="/images/arrow.svg"
                    className="ml-1 group-hover:rotate-45 ease-out duration-150"
                    alt="arrow"
                />
                </h2>
            </div>
        </Link>
    );
};

export default Calltoaction;