import Link from "next/link";

const Awards = () => {
  const partnerGroup = [
    { id: 1, logo: "premio-1" },
    { id: 2, logo: "premio-2" },
    { id: 3, logo: "premio-3" },
    { id: 4, logo: "premio-4" },
    { id: 5, logo: "premio-5" },
    { id: 6, logo: "premio-6" },
    { id: 7, logo: "premio-7" },
    { id: 8, logo: "premio-8" },
  ];
  return (
    <>
      <div className="dark:bg-jacarta-1100 bg-jacarta-1100 pb-16">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="mb-6 text-center font-display text-3xl font-medium text-white md:text-5xl">
            Premios y Concursos
          </h2>
        </div>
        <div className="container bg">
          <div className="grid grid-cols-2 py-8 md:grid-cols-4">
            {partnerGroup.map((item) => (
              <div
                key={item.id}
                style={{
                  display:'flex',
                  justifyContent:'center',
                  alignItems:'center',
                  margin:'10px 20px'
                }}
              >
                <img
                  src={`/images/awards/${item.logo}.png`}
                  alt="Premios y concursos"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Awards;
